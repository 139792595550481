import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function list() {
  const res = await graphqlClient.query({
    query: gql`{
        role {
          id
          name
          description
        }
      }
    `
  });

  return res.data.role;
}

async function getByName(name) {
  const res = await graphqlClient.query({
    query: gql`
    {
      role(where:{
        name: {
          _eq: "${name}"
        }
      }) {
        id
        name
      }
    }
    `
  });
  
  return (res.data.role.length) ? res.data.role[0] : null;
}

export default {
  list,
  getByName
};