<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <div class="vx-col w-full lg:w-3/4 mb-base">
          <vx-card class="p-2">
            <CropImage
              class="crop-image"
              lang-type="es-MX"
              v-model="isShowAppCrop"
              @crop-success="cropSuccess"
              :no-square="true"
            />
            <vs-avatar
              v-if="!imageReadyToUplad"
              class="mx-auto mb-4 block"
              size="180px"
              :src="imageUrl"
              @click="openAppCrop"
            />
            <vs-avatar
              v-if="imageReadyToUplad"
              class="mx-auto mb-4 block"
              size="180px"
              :src="profileNewImageTemp"
            />
            <vs-row
              vs-align="center"
              vs-type="flex"
              vs-justify="space-around"
              vs-w="12"
            >
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
              </vs-col>
            </vs-row>
            <div>
              <br />
              <h4 class="mb-3">Datos generales:</h4>
              <div class="centerx labelx mb-4">
                <div class="flex w-full mb-2">
                  <vs-input
                    icon-pack="feather"
                    icon="icon-user"
                    v-model="firstName"
                    class="is-label-placeholder w-full"
                    label-placeholder="Nombres"
                  />
                  <div
                    class="sm:w-1/9 p-2 flex"
                    v-if="!firstName == '' && firstName != user.first_name"
                  >
                    <vs-button
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-save"
                      class="lg:w-2/4 mt-3"
                      @click="
                        updateUserProfile(
                          'first_name',
                          firstName,
                          'de primer nombre.'
                        )
                      "
                    ></vs-button>
                  </div>
                </div>

                <div class="flex w-full mb-2">
                  <vs-input
                    icon-pack="feather"
                    icon="icon-user"
                    v-model="lastName"
                    class="is-label-placeholder w-full"
                    label-placeholder="Apellido"
                  />
                  <div
                    class="sm:w-1/9 p-2 flex"
                    v-if="!lastName == '' && lastName != user.last_name"
                  >
                    <vs-button
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-save"
                      class="lg:w-2/4 mt-3"
                      @click="
                        updateUserProfile(
                          'last_name',
                          lastName,
                          'de primer apellido.'
                        )
                      "
                    ></vs-button>
                  </div>
                </div>
                <div class="flex w-full mb-2">
                  <vs-input
                    icon-pack="feather"
                    icon="icon-phone"
                    v-model="phone"
                    class="is-label-placeholder w-full"
                    label-placeholder="Teléfono"
                  />
                  <div
                    class="sm:w-1/9 p-2 flex mb-3"
                    v-if="!phone == '' && phone != user.phone"
                  >
                    <vs-button
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-save"
                      class="lg:w-2/4 mt-3"
                      @click="updateUserProfile('phone', phone, 'de teléfono.')"
                    ></vs-button>
                  </div>
                </div>

                <div class="flex w-full mb-2">
                  <vs-input
                    icon-pack="feather"
                    icon="icon-clipboard"
                    v-model="pipedriveId"
                    class="is-label-placeholder w-full"
                    label-placeholder="Pipedrive id"
                  />
                  <div
                    class="sm:w-1/9 p-2 flex mb-3"
                    v-if="!pipedriveId == '' && pipedriveId != user.pipedrive_id"
                  >
                    <vs-button
                      color="success"
                      type="filled"
                      icon-pack="feather"
                      icon="icon-save"
                      class="lg:w-2/4 mt-3"
                      @click="
                        updateUserProfile(
                          'pipedrive_id',
                          pipedriveId,
                          ' pipedrive id.'
                        )
                      "
                    ></vs-button>
                  </div>
                </div>
                <br />
                <h4 class="mt-4 mb-1">Remplazar contraseña:</h4>
                <div class="flex w-full">
                  <vs-input
                    icon-pack="feather"
                    icon="icon-lock"
                    label-placeholder="Nueva contraseña"
                    v-model="password"
                    type="password"
                    v-validate="'required|min:8|max:20'"
                    name="Contraseña"
                    ref="Contrasena"
                    class="is-label-placeholder w-full"
                  />
                </div>
                <span
                  class="text-danger text-sm pt-4"
                  v-show="errors.has('Contraseña')"
                  >{{ errors.first("Contraseña") }}</span
                >
                <div class="flex w-full mt-2">
                  <vs-input
                    icon-pack="feather"
                    icon="icon-lock"
                    label-placeholder="Confirmación de contraseña"
                    v-model="repeatPassword"
                    type="password"
                    name="Confirmar-contrasena"
                    v-validate="'required|confirmed:Contrasena'"
                    class="is-label-placeholder w-full"
                  />
                </div>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('Confirmar-contrasena')"
                  >{{ errors.first("Confirmar-contrasena") }}</span
                >
                <div class="mt-2">
                  <vs-button
                    v-if="passwordValidation"
                    color="success"
                    type="filled"
                    @click="updatePassword"
                    >Guardar</vs-button
                  >
                </div>
              </div>
            </div>
          </vx-card>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import S3 from "aws-s3";
import myUpload from "vue-image-crop-upload";
import { userApi, accountApi } from "../services";
import slugify from "../../../helpers/slugify";

export default {
  components: {
    CropImage: myUpload,
  },
  data() {
    return {
      password: "",
      repeatPassword: "",
      profileNewImage: "",
      profileNewImageTemp: "",
      imageReadyToUplad: false,
      isShowAppCrop: false,
      user: null,
      firstName: "",
      lastName: "",
      phone: "",
      imageUrl: "",
      accountId: null,
      pipedriveId: null
    };
  },
  async mounted() {
    const user = this.$store.getters["auth/getUser"];
    const userId = user.userId;
    this.accountId = user.id;
    this.user = await userApi.get(userId);
    this.firstName = this.user.first_name;
    this.lastName = this.user.last_name;
    this.phone = this.user.phone;
    this.imageUrl = this.user.avatar;
    this.pipedriveId = this.user.pipedrive_id;

    this.$store.state.project.breadcrumbs = [{
      pageName: 'Perfil personal' , 
      route:'/su-perfil'}
    ];
  },
  computed: {
    config() {
      const developer = this.user.accounts.length
        ? this.user.accounts[0].property_developer.name
        : null;
      const developerName = slugify(developer);

      return {
        bucketName: "flattlo-app",
        dirName: `${developerName}/team`,
        region: "us-east-1",
        accessKeyId: process.env.VUE_APP_AWS_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SK,
        s3Url: process.env.VUE_APP_S3_URL
      };
    },
    S3Client() {
      return new S3(this.config);
    },
    passwordValidation() {
      if (this.password !== "" && this.password === this.repeatPassword) {
        return true;
      }
      return false;
    }
  },
  methods: {
    openAppCrop() {
      this.isShowAppCrop = !this.isShowAppCrop;
    },
    async cropSuccess(data) {
      try {
        this.profileNewImageTemp = data;
        this.imageReadyToUplad = true;
        const image = await this.dataURIToFile(data);
        this.profileNewImage = image;
        const upload = await this.S3Client.uploadFile(
          image,
          `${this.firstName}_${this.lastName}`
        );
        this.updateUserProfile("avatar", upload.location, "Foto de Perfil");
      } catch (err) {
        this.$vs.notify({
          title: `📝 Imágen de perfil no pudo ser actualizada.`,
          text: `Intente más tarde.`,
          color: "danger"
        });
      }
    },

    dataURIToFile: function(data) {
      let type = data.split(";", [1])[0];
      type = type.split(":", [2])[1];
      const date = new Date();
      return fetch(data)
        .then(response => response.arrayBuffer())
        .then(response => new File([response], date.getDate(), { type }));
    },
    async updateUserProfile(fieldForUpdate, valueToAdd, descriptionToUser) {
      const fields = {};
      fields[fieldForUpdate] = valueToAdd;
      try {
        this.user = await userApi.update(this.user.id, fields);
        this.$vs.notify({
          title: `📝 El campo ${descriptionToUser}`,
          text: `Fue editado correctamente.`,
          color: "success"
        });
        this.firstName = this.user.first_name;
        this.lastName = this.user.last_name;
        this.phone = this.user.phone;
        this.imageUrl = this.user.avatar;
      } catch (e) {
        this.$vs.notify({
          title: `📝 El campo ${descriptionToUser} no pudo ser actualizado`,
          text: `Intente más tarde.`,
          color: "danger"
        });
      }
    },
    async updatePassword() {
      try {
        await accountApi.updatePassword(this.accountId, this.password);
        this.$vs.notify({
          title: `📝 Contraseña actualizada.`,
          text: `Cierra sesión para generar ver los cambios.`,
          color: "success"
        });
      } catch (e) {
        this.$vs.notify({
          title: `📝 El campo contraseña no pudo ser actualizado`,
          text: `Intente más tarde.`,
          color: "danger"
        });
      }
    }
  }
};
</script>
