import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function deleteUserProjectById(userProjectId) {
    const res  = await graphqlClient.mutate({
        mutation: gql`
          mutation deleteUserProject($userProjectId: uuid!) {
            delete_user_project_by_pk(id: $userProjectId) {
              id
              project {
                id
                name
              }
            }
          }
        `,
        variables: {
          userProjectId
        }
      });

  return res.data.delete_user_project_by_pk;
}

const getUserProject = async userId => {
  const res = await graphqlClient.query({
    query: gql`
    {
      user_project(where:{
        user_id: {
          _eq: "${userId}"
        }
      }){
        id
        project {
          id
          name
        }
      }
    }
    `
  });

  return res.data.user_project;
};

const get = async userId => {
  const res = await graphqlClient.query({
    query: gql`
    {
      user(where:{
        id: {
          _eq: "${userId}"
        }
      }) {
        id
        first_name
        last_name
        phone
        avatar
        pipedrive_id
        accounts {
          id
          property_developer {
            id
            name
          }
        }
      }
    }
    `
  });

  return (res.data.user.length) ? res.data.user[0] : null;
};

async function update(userId, fields) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation updUser($fields: user_set_input!) {
        update_user_by_pk(pk_columns: {
          id: "${userId}"
        }, _set: $fields) {
          id
          first_name
          last_name
          avatar
          phone
        }
      }
    `,
    variables: {
      fields: fields
    }
  });
  return response.data.update_user_by_pk;
}

export default {
  deleteUserProjectById,
  getUserProject,
  get,
  update
};