var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vs-row',[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}},[_c('div',{staticClass:"vx-col w-full lg:w-3/4 mb-base"},[_c('vx-card',{staticClass:"p-2"},[_c('CropImage',{staticClass:"crop-image",attrs:{"lang-type":"es-MX","no-square":true},on:{"crop-success":_vm.cropSuccess},model:{value:(_vm.isShowAppCrop),callback:function ($$v) {_vm.isShowAppCrop=$$v},expression:"isShowAppCrop"}}),(!_vm.imageReadyToUplad)?_c('vs-avatar',{staticClass:"mx-auto mb-4 block",attrs:{"size":"180px","src":_vm.imageUrl},on:{"click":_vm.openAppCrop}}):_vm._e(),(_vm.imageReadyToUplad)?_c('vs-avatar',{staticClass:"mx-auto mb-4 block",attrs:{"size":"180px","src":_vm.profileNewImageTemp}}):_vm._e(),_c('vs-row',{attrs:{"vs-align":"center","vs-type":"flex","vs-justify":"space-around","vs-w":"12"}},[_c('vs-col',{attrs:{"vs-type":"flex","vs-justify":"center","vs-align":"center","vs-w":"12"}})],1),_c('div',[_c('br'),_c('h4',{staticClass:"mb-3"},[_vm._v("Datos generales:")]),_c('div',{staticClass:"centerx labelx mb-4"},[_c('div',{staticClass:"flex w-full mb-2"},[_c('vs-input',{staticClass:"is-label-placeholder w-full",attrs:{"icon-pack":"feather","icon":"icon-user","label-placeholder":"Nombres"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),(!_vm.firstName == '' && _vm.firstName != _vm.user.first_name)?_c('div',{staticClass:"sm:w-1/9 p-2 flex"},[_c('vs-button',{staticClass:"lg:w-2/4 mt-3",attrs:{"color":"success","type":"filled","icon-pack":"feather","icon":"icon-save"},on:{"click":function($event){return _vm.updateUserProfile(
                        'first_name',
                        _vm.firstName,
                        'de primer nombre.'
                      )}}})],1):_vm._e()],1),_c('div',{staticClass:"flex w-full mb-2"},[_c('vs-input',{staticClass:"is-label-placeholder w-full",attrs:{"icon-pack":"feather","icon":"icon-user","label-placeholder":"Apellido"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),(!_vm.lastName == '' && _vm.lastName != _vm.user.last_name)?_c('div',{staticClass:"sm:w-1/9 p-2 flex"},[_c('vs-button',{staticClass:"lg:w-2/4 mt-3",attrs:{"color":"success","type":"filled","icon-pack":"feather","icon":"icon-save"},on:{"click":function($event){return _vm.updateUserProfile(
                        'last_name',
                        _vm.lastName,
                        'de primer apellido.'
                      )}}})],1):_vm._e()],1),_c('div',{staticClass:"flex w-full mb-2"},[_c('vs-input',{staticClass:"is-label-placeholder w-full",attrs:{"icon-pack":"feather","icon":"icon-phone","label-placeholder":"Teléfono"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(!_vm.phone == '' && _vm.phone != _vm.user.phone)?_c('div',{staticClass:"sm:w-1/9 p-2 flex mb-3"},[_c('vs-button',{staticClass:"lg:w-2/4 mt-3",attrs:{"color":"success","type":"filled","icon-pack":"feather","icon":"icon-save"},on:{"click":function($event){return _vm.updateUserProfile('phone', _vm.phone, 'de teléfono.')}}})],1):_vm._e()],1),_c('div',{staticClass:"flex w-full mb-2"},[_c('vs-input',{staticClass:"is-label-placeholder w-full",attrs:{"icon-pack":"feather","icon":"icon-clipboard","label-placeholder":"Pipedrive id"},model:{value:(_vm.pipedriveId),callback:function ($$v) {_vm.pipedriveId=$$v},expression:"pipedriveId"}}),(!_vm.pipedriveId == '' && _vm.pipedriveId != _vm.user.pipedrive_id)?_c('div',{staticClass:"sm:w-1/9 p-2 flex mb-3"},[_c('vs-button',{staticClass:"lg:w-2/4 mt-3",attrs:{"color":"success","type":"filled","icon-pack":"feather","icon":"icon-save"},on:{"click":function($event){return _vm.updateUserProfile(
                        'pipedrive_id',
                        _vm.pipedriveId,
                        ' pipedrive id.'
                      )}}})],1):_vm._e()],1),_c('br'),_c('h4',{staticClass:"mt-4 mb-1"},[_vm._v("Remplazar contraseña:")]),_c('div',{staticClass:"flex w-full"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8|max:20'),expression:"'required|min:8|max:20'"}],ref:"Contrasena",staticClass:"is-label-placeholder w-full",attrs:{"icon-pack":"feather","icon":"icon-lock","label-placeholder":"Nueva contraseña","type":"password","name":"Contraseña"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Contraseña')),expression:"errors.has('Contraseña')"}],staticClass:"text-danger text-sm pt-4"},[_vm._v(_vm._s(_vm.errors.first("Contraseña")))]),_c('div',{staticClass:"flex w-full mt-2"},[_c('vs-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:Contrasena'),expression:"'required|confirmed:Contrasena'"}],staticClass:"is-label-placeholder w-full",attrs:{"icon-pack":"feather","icon":"icon-lock","label-placeholder":"Confirmación de contraseña","type":"password","name":"Confirmar-contrasena"},model:{value:(_vm.repeatPassword),callback:function ($$v) {_vm.repeatPassword=$$v},expression:"repeatPassword"}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Confirmar-contrasena')),expression:"errors.has('Confirmar-contrasena')"}],staticClass:"text-danger text-sm"},[_vm._v(_vm._s(_vm.errors.first("Confirmar-contrasena")))]),_c('div',{staticClass:"mt-2"},[(_vm.passwordValidation)?_c('vs-button',{attrs:{"color":"success","type":"filled"},on:{"click":_vm.updatePassword}},[_vm._v("Guardar")]):_vm._e()],1)])])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }