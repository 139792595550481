import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function updatePassword(accountId, password) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation {
        updatePassword(password: "${password}", token: "${accountId}")
      }
    `
  });
  return response.data.updatePassword;
}

async function getByEmail(email) {
  const res = await graphqlClient.query({
    query: gql`
    {
      account(where: {
        email: {
          _eq: "${email}"
        },
        deleted: {
          _eq: false
        }
      }) {
        email
      }
    }
    `
  });

  return res.data.account;
}

async function createAccount(
  firstName,
  lastName,
  areaCode,
  phone,
  email,
  pipedriveId,
  password,
  roleId
) {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation(
        $email: String!
        $pipedriveId: String
        $firstName: String!
        $lastName: String!
        $roleId: ID!
        $areaCode: String!
        $phone: String!
        $password: String!
      ) {
        createAccount(
          createAccountInput: {
            email: $email
            first_name: $firstName
            last_name: $lastName
            phone: $phone
            area_code: $areaCode
            password: $password
            role_id: $roleId
            pipedrive_id: $pipedriveId
          }
        ) {
          id
          email
          active
          user_id
        }
      }
    `,
    variables: {
      email,
      pipedriveId,
      firstName,
      lastName,
      roleId,
      areaCode,
      phone,
      password
    }
  });

  return response.data.createAccount;
}

const addAccountToDeveloper = async (userId, developerId) => {
  const response = await graphqlClient.mutate({
    mutation: gql`
      mutation relateAccount($userId: uuid!, $developerId: uuid!) {
        update_account_by_pk(
          _set: { property_developer_id: $developerId }
          pk_columns: { id: $userId }
        ) {
          id
        }
      }
    `,
    variables: {
      userId,
      developerId
    }
  });

  return response.data.update_account_by_pk;
};

export default {
  updatePassword,
  getByEmail,
  createAccount,
  addAccountToDeveloper
};
